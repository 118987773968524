import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import ServiceInfo from "@/components/service-info";
import ServiceOne from "@/components/service-one";
import BenefitOne from "@/components/benefit-one";
// import SponsorOne from "@/components/sponsor-one";
import { Helmet as Head } from "react-helmet";

const Service = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Service Page">
        <Head>
          <title>Pest Extermination Solutions | Nifixeci</title>
          <meta
            name="description"
            content="We have a set of high-quality pest extermination solutions for you.
            Select the one that suits your pest issues."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Services" name="Service" />
        <ServiceInfo />
        <ServiceOne />
        <BenefitOne />
        {/* <SponsorOne /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Service;
